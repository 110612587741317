<template>
    <el-date-picker
        :id="id"
        ref="datepicker"
        v-bind="$attrs"
        :end-placeholder="$t('form.date-range.end-placeholder')"
        :format="formatConverted"
        :clearable="clearable"
        :picker-options="pickerOptions"
        :range-separator="$t('form.date-range.range-separator')"
        :start-placeholder="$t('form.date-range.start-placeholder')"
        :value="value"
        class="form-daterangepicker"
        :class="{'form-daterangepicker--clearable': clearable}"
        type="daterange"
        @input="$emit('input', $event || [])"
    />
</template>

<script>
    import DatePicker from 'element-ui/lib/date-picker';
    import DateFormat from '../../mixins/Inputs/DateFormat';

    let counter = 0;

    export default {
        mixins: [DateFormat],

        components: {
            'el-date-picker': DatePicker,
        },

        props: {
            id: {
                type: String,
                default: 'daterangepicker_' + ++counter,
            },

            value: {
                type: Array,
                default() {
                    return [];
                },
            },

            clearable: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            pickerOptions() {
                const today = new Date();

                return {
                    firstDayOfWeek: 1,
                    shortcuts: [
                        // Months
                        ...Array.from(Array(12).keys()).map(month => {
                            return {
                                text: this.$d(new Date(today.getFullYear(), month, 1), 'monthLong'),
                                onClick(picker) {
                                    picker.$emit('pick', [new Date(today.getFullYear(), month, 1), new Date(today.getFullYear(), month + 1, 0)]);
                                },
                            };
                        }),
                        {
                            text: '──────',
                            onClick(picker) {},
                        },
                        // Quarters
                        ...[1, 2, 3, 4].map(quarter => {
                            return {
                                text: this.$t('form.date-range.shortcuts.quarter', { quarter }),
                                onClick(picker) {
                                    picker.$emit('pick', [new Date(today.getFullYear(), (quarter - 1) * 3, 1), new Date(today.getFullYear(), quarter * 3, 0)]);
                                },
                            };
                        }),
                        {
                            text: '──────',
                            onClick(picker) {},
                        },
                        // Year
                        {
                            text: this.$t('form.date-range.shortcuts.year'),
                            onClick(picker) {
                                picker.$emit('pick', [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)]);
                            },
                        },
                    ],
                };
            },
        },

        mounted() {
            // Focus input when the associated label is clicked
            document.addEventListener('click', (e) => {
                if (!this.id || e.target.tagName !== 'LABEL' || e.target.htmlFor !== this.id) {
                    return;
                }

                this.$refs.datepicker && this.$refs.datepicker.focus();
            });

            Array.from(this.$refs.datepicker.$el.querySelectorAll('input')).forEach(input => {
                input.autocomplete = 'off';
            });
        },
    };
</script>
